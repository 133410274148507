exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projects-lerna-monorepo-tsx": () => import("./../../../src/pages/projects/lerna-monorepo.tsx" /* webpackChunkName: "component---src-pages-projects-lerna-monorepo-tsx" */),
  "component---src-pages-projects-spear-cart-tsx": () => import("./../../../src/pages/projects/spear-cart.tsx" /* webpackChunkName: "component---src-pages-projects-spear-cart-tsx" */),
  "component---src-pages-projects-spear-dashboard-tsx": () => import("./../../../src/pages/projects/spear-dashboard.tsx" /* webpackChunkName: "component---src-pages-projects-spear-dashboard-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-pages-projects-webpack-5-module-federation-tsx": () => import("./../../../src/pages/projects/webpack-5-module-federation.tsx" /* webpackChunkName: "component---src-pages-projects-webpack-5-module-federation-tsx" */)
}

